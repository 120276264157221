import {
  Stack,
  Button,
  HStack,
  PinInput,
  PinInputField
} from "@chakra-ui/react";
import { login2FA } from "app/store";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { loadingStop } from "app/store/reducers/auth.reducer";

export const TwoFALoginContainer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [code, setCode] = useState("");
  const [email, isLoading, inviteToken] = useAppSelector((state) => [
    state.auth.email,
    state.auth.isLoading2FA,
    state.auth.inviteToken
  ]);

  const submitPinHandler = useCallback(
    async (e: { preventDefault: () => void }) => {
      e.preventDefault();
      if (code.length !== 6 || !email) return;
      dispatch(login2FA(navigate, { email, code, inviteToken }));
    },
    [code]
  );

  useEffect(() => {
    if (code.length < 6 || !email) return;
    dispatch(login2FA(navigate, { email, code, inviteToken }));
  }, [code]);

  useEffect(() => {
    dispatch(loadingStop());
  }, []);

  return (
    <div className="w-11/12 p-6 bg-gray-100 mdx:w-5/12 sm:w-9/12">
      <Stack alignItems={"center"}>
        <p className="text-gray-900 text-heading100 mdx:text-heading300">
          Welcome Back!
        </p>
        <p className="mt-1 text-gray-700">
          Enter your two factor authentication code below
        </p>
        <HStack py="10">
          <PinInput value={code} onChange={(value) => setCode(value)}>
            <PinInputField borderColor={"primary.500"} />
            <PinInputField borderColor={"primary.500"} />
            <PinInputField borderColor={"primary.500"} />
            <PinInputField borderColor={"primary.500"} />
            <PinInputField borderColor={"primary.500"} />
            <PinInputField borderColor={"primary.500"} />
          </PinInput>
        </HStack>
        <div className="w-full mt-8 mdx:mt-12">
          <Button
            isDisabled={code.length < 6}
            isLoading={isLoading}
            className="mdx:p-6 bg-primary-600"
            textColor="white"
            type="submit"
            bgColor="#340072"
            w="full"
            _hover={{ bgColor: "#240051" }}
            onClick={submitPinHandler}
          >
            <div className="flex items-center">
              <p>Submit</p>
            </div>
          </Button>
        </div>
      </Stack>
    </div>
  );
};
