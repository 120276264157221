import {
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Select
} from "@chakra-ui/react";
import { UilEnvelope } from "@iconscout/react-unicons";
import { ReactComponent as Tick } from "app/assets/svg/tick-circle.svg";
import { ReactComponent as ErrorTick } from "app/assets/svg/errorTick.svg";
import { UilGlobe } from "@iconscout/react-unicons";
import { UilPadlock } from "@iconscout/react-unicons";
import { UilUser, UilUserCheck } from "@iconscout/react-unicons";
import { Link, useNavigate } from "react-router-dom";
import { signUpUser } from "app/store";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { useEffect, useState } from "react";
import { countries } from "data/utils";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { encryptKey } from "data/utils/encryption";
export const RegisterContainer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.auth.loading);
  const [phoneValue, setPhoneValue] = useState<any>("");

  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get("token");
  const inviteEmail = searchParams.get("email");
  const teamInvite = searchParams.get("teaminvite");

  const [values, setValues] = useState({
    email: teamInvite ? inviteEmail! : "",
    firstName: "",
    middleName: "",
    lastName: "",
    password: "",
    phone: "",
    country: "",
    confirmPassword: "",
    referralCode: "",
    signupMode: teamInvite ? "team-member-invite-link" : "normal-auth"
  });

  const {
    email,
    password,
    firstName,
    middleName,
    lastName,
    country,
    referralCode
  } = values;
  const handleChange =
    (name: any) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      setValues({ ...values, [name]: e.target.value });
    };

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const handleClick = () => setShow(!show);
  const handleClick2 = () => setShow2(!show2);

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const encryptedPassword = encryptKey(values.password);
    const data = { ...values, password: encryptedPassword };
    dispatch(signUpUser(navigate, data, teamInvite, token));
  };

  const [phoneLength, setPhoneLength] = useState<boolean>(false);
  const [validLength, setValidLength] = useState<boolean>(false);
  const [upperCase, setUpperCase] = useState<boolean>(false);
  const [hasNumber, setHasNumber] = useState<boolean>(false);
  const [specialChar, setSpecialChar] = useState<boolean>(false);
  const [requiredLength] = useState<number>(8);

  useEffect(() => {
    setValidLength(values.password.length >= requiredLength ? true : false);
    setUpperCase(values.password.toLowerCase() !== values.password);
    setHasNumber(/\d/.test(values.password));
    setSpecialChar(
      /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(values.password)
    );
    setPhoneLength(
      values.phone?.length >= 5 && values.phone?.length <= 20 ? true : false
    );
  }, [values.password, requiredLength, values.phone]);
  const confimedPassword =
    values.confirmPassword === values.password ? true : false;

  const filled =
    validLength &&
    hasNumber &&
    upperCase &&
    specialChar &&
    requiredLength &&
    phoneLength &&
    confimedPassword
      ? true
      : false;

  useEffect(() => {
    setValues({ ...values, phone: phoneValue });
  }, [phoneValue]);

  return (
    <div className="w-11/12 p-4 bg-gray-100 lg:p-8 mdx:w-5/12 sm:w-9/12">
      <p className="text-gray-900 text-heading100 mdx:text-heading300">
        Create Account
      </p>
      {teamInvite ? (
        <>
          <p className="mt-1 text-gray-700">
            Create your profile on Payborda to access Businesses you have been
            invited to.
          </p>
        </>
      ) : (
        <>
          <p className="mt-1 text-gray-700">
            Please provide the details below to create your Payborda account for
            free.
          </p>
        </>
      )}

      <div className="mt-6 mdx:mt-10 authForm">
        <form onSubmit={handleSubmit}>
          <div className="my-4">
            <label className="text-gray-600 ">First Name</label>
            <InputGroup className="items-center">
              <InputLeftElement
                top="auto"
                pointerEvents="none"
                children={<UilUser color="#B1B4BF" />}
              />
              <Input
                value={firstName}
                onChange={handleChange("firstName")}
                pattern="^[^\s].+[a-zA-Z]+$"
                focusBorderColor="none"
                type="text"
                placeholder="First Name"
                color="#444B59"
                isRequired
                _placeholder={{ opacity: 1, color: "#667085" }}
              />
            </InputGroup>
          </div>
          <div className="my-4">
            <label className="text-gray-600 ">Last Name</label>
            <InputGroup className="items-center">
              <InputLeftElement
                top="auto"
                pointerEvents="none"
                children={<UilUser color="#B1B4BF" />}
              />
              <Input
                value={lastName}
                onChange={handleChange("lastName")}
                pattern="^[^\s].+[a-zA-Z]+$"
                focusBorderColor="none"
                type="text"
                placeholder="Last Name"
                color="#444B59"
                isRequired
                _placeholder={{ opacity: 1, color: "#667085" }}
              />
            </InputGroup>
          </div>
          <div className="my-4">
            <label className="text-gray-600 ">Middle Name (Optional)</label>
            <InputGroup className="items-center">
              <InputLeftElement
                top="auto"
                pointerEvents="none"
                children={<UilUser color="#B1B4BF" />}
              />
              <Input
                value={middleName}
                onChange={handleChange("middleName")}
                pattern="^[^\s].+[a-zA-Z]+$"
                focusBorderColor="none"
                type="text"
                placeholder="Middle Name"
                color="#444B59"
                _placeholder={{ opacity: 1, color: "#667085" }}
              />
            </InputGroup>
          </div>
          <div className="my-4">
            <label className="text-gray-600 ">Email</label>
            <InputGroup className="items-center">
              <InputLeftElement
                top="auto"
                pointerEvents="none"
                children={<UilEnvelope color="#B1B4BF" />}
              />
              <Input
                value={email}
                onChange={handleChange("email")}
                isDisabled={teamInvite ? true : false}
                focusBorderColor="none"
                type="email"
                placeholder="Email"
                color="#444B59"
                isRequired
                _placeholder={{ opacity: 1, color: "#667085" }}
              />
            </InputGroup>
          </div>

          <div className="my-4">
            <FormControl isInvalid={/^0*$/.test(values.phone)}>
              <FormLabel>Phone Number</FormLabel>
              <InputGroup
                size="md"
                className="border border-[#cccfd6ec] rounded-md"
              >
                <PhoneInput
                  required
                  international
                  defaultCountry="NG"
                  name="phone"
                  value={phoneValue}
                  onChange={setPhoneValue}
                />
              </InputGroup>
            </FormControl>
          </div>
          <div className="my-4">
            <label className="text-gray-600 ">Country</label>
            <InputGroup className="items-center">
              <InputLeftElement
                top="auto"
                pointerEvents="none"
                children={<UilGlobe color="#B1B4BF" />}
              />
              <Select
                value={country}
                onChange={handleChange("country")}
                placeholder="Country"
                color="#444B59"
                isRequired
                _placeholder={{ opacity: 1, color: "#667085" }}
                className="selectWithIcon"
              >
                {countries.map((country: any) => (
                  <option key={country.code} value={country.name}>
                    {country.name} {country.flag}
                  </option>
                ))}
              </Select>
            </InputGroup>
          </div>
          <div className="my-4">
            <label className="text-gray-600 ">Password</label>
            <InputGroup className="items-center">
              <InputLeftElement
                top="auto"
                pointerEvents="none"
                children={<UilPadlock color="#B1B4BF" />}
              />
              <Input
                value={password}
                onChange={handleChange("password")}
                focusBorderColor="none"
                type={show ? "text" : "password"}
                placeholder="Password"
                color="#667085"
                isRequired
                _placeholder={{ opacity: 1, color: "#667085" }}
              />
              <InputRightElement width="4.5rem" top="auto">
                <Button size="sm" onClick={handleClick}>
                  {show ? "Hide" : "Show"}
                </Button>
              </InputRightElement>
            </InputGroup>
          </div>
          <div>
            {values.password && (
              <div className="text-[12px]">
                <div className="flex flex-wrap items-center">
                  {validLength ? (
                    <div className="flex items-center mr-1">
                      <Tick className="w-5 mr-1" />
                      <p className="text-success-500">Minimum 8 characters</p>
                    </div>
                  ) : (
                    <div className="flex items-center mr-1">
                      <ErrorTick className="w-5 mr-1" />
                      <p className="text-[red]">Minimum 8 characters</p>
                    </div>
                  )}
                  {specialChar ? (
                    <div className="flex items-center mr-1">
                      <Tick className="w-5 mr-1" />
                      <p className="text-success-500">Special character</p>
                    </div>
                  ) : (
                    <div className="flex items-center mr-1">
                      <ErrorTick className="w-5 mr-1" />
                      <p className="text-[red]">Special character</p>
                    </div>
                  )}
                  {upperCase ? (
                    <div className="flex items-center mr-1">
                      <Tick className="w-5 mr-1" />
                      <p className="text-success-500">Uppercase letter</p>
                    </div>
                  ) : (
                    <div className="flex items-center mr-1">
                      <ErrorTick className="w-5 mr-1" />
                      <p className="text-[red]">Uppercase letter</p>
                    </div>
                  )}
                  {hasNumber ? (
                    <div className="flex items-center mr-1">
                      <Tick className="w-5 mr-1" />
                      <p className="text-success-500">Has number</p>
                    </div>
                  ) : (
                    <div className="flex items-center mr-1">
                      <ErrorTick className="w-5 mr-1" />
                      <p className="text-[red]">Has number</p>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="my-4">
            <label className="text-gray-600 ">Confirm Password</label>
            <InputGroup className="items-center">
              <InputLeftElement
                top="auto"
                pointerEvents="none"
                children={<UilPadlock color="#B1B4BF" />}
              />
              <Input
                value={values.confirmPassword}
                onChange={handleChange("confirmPassword")}
                focusBorderColor="none"
                type={show2 ? "text" : "password"}
                placeholder="Confirm Password"
                color="#667085"
                isRequired
                _placeholder={{ opacity: 1, color: "#667085" }}
              />
              <InputRightElement width="4.5rem" top="auto">
                <Button size="sm" onClick={handleClick2}>
                  {show2 ? "Hide" : "Show"}
                </Button>
              </InputRightElement>
            </InputGroup>
          </div>
          <div className="my-4">
            <label className="text-gray-600 ">Referral Code (Optional)</label>
            <InputGroup className="items-center">
              <InputLeftElement
                top="auto"
                pointerEvents="none"
                children={<UilUserCheck color="#B1B4BF" />}
              />
              <Input
                value={referralCode}
                onChange={handleChange("referralCode")}
                focusBorderColor="none"
                type="text"
                placeholder="Referral Code"
                color="#444B59"
                _placeholder={{ opacity: 1, color: "#667085" }}
              />
            </InputGroup>
          </div>
          <div className="mt-8 mdx:mt-12">
            <Button
              isDisabled={!filled}
              isLoading={isLoading}
              className="bg-primary-600"
              textColor="white"
              bgColor="#340072"
              w="full"
              type="submit"
              padding="6"
              _hover={{ bgColor: "#240051" }}
            >
              {" "}
              <div className="flex items-center">Create Account</div>
            </Button>
          </div>
        </form>
      </div>

      <div>
        <p className="mt-3 font-light text-center text-primary-600">
          Already have an account?{" "}
          <span className="font-bold">
            <Link to={"/auth/login"}>Sign in here</Link>
          </span>
        </p>
      </div>
    </div>
  );
};
