import { Button, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { UilPadlock } from "@iconscout/react-unicons";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { resetPassword } from "app/store";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ReactComponent as Tick } from "app/assets/svg/tick-circle.svg";
import { ReactComponent as ErrorTick } from "app/assets/svg/errorTick.svg";
import { ResetPasswordSuccessContainer } from "./reset-successful-container";
export const ResetPasswordContainer = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.auth.loading);
  const resetSuccessfull = useAppSelector(
    (state) => state?.auth?.passwordResetSuccessfull
  );
  const { email } = useParams<{ email: string }>();
  const { token } = useParams<{ token: string }>();
  const [values, setValues] = useState({
    password: "",
    confirmPassword: "",
    resetToken: `${token}`,
    email: `${email}`
  });
  const { password } = values;
  const [validLength, setValidLength] = useState<boolean>(false);
  const [upperCase, setUpperCase] = useState<boolean>(false);
  const [hasNumber, setHasNumber] = useState<boolean>(false);
  const [specialChar, setSpecialChar] = useState<boolean>(false);
  const [requiredLength] = useState<number>(8);
  useEffect(() => {
    setValidLength(values.password.length >= requiredLength ? true : false);
    setUpperCase(values.password.toLowerCase() !== values.password);
    setHasNumber(/\d/.test(values.password));
    setSpecialChar(
      /[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(values.password)
    );
  }, [values.password, requiredLength]);
  const confimedPassword =
    values.confirmPassword === values.password ? true : false;
  const filled =
    validLength &&
    hasNumber &&
    upperCase &&
    specialChar &&
    requiredLength &&
    confimedPassword
      ? true
      : false;
  const handleChange =
    (name: any) =>
    (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      setValues({ ...values, [name]: e.target.value });
    };
  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(resetPassword(values));
  };
  return (
    <>
      {resetSuccessfull ? (
        <ResetPasswordSuccessContainer />
      ) : (
        <>
          <div className="w-11/12 p-6 bg-gray-100 mdx:w-5/12 sm:w-9/12">
            <p className="text-gray-900 text-heading100 mdx:text-heading300">
              Reset Password
            </p>
            <p className="mt-1 text-gray-700">
              Please create your new password
            </p>
            <div className="mt-4 mdx:mt-10 authForm">
              <form onSubmit={handleSubmit}>
                <div className="my-4">
                  <InputGroup className="items-center">
                    <InputLeftElement
                      top="auto"
                      pointerEvents="none"
                      children={<UilPadlock color="#B1B4BF" />}
                    />
                    <Input
                      value={password}
                      onChange={handleChange("password")}
                      type="password"
                      placeholder="New Password"
                      color="#444B59"
                      isRequired
                      _placeholder={{ opacity: 1, color: "#667085" }}
                    />
                  </InputGroup>
                </div>
                <div>
                  {values.password && (
                    <div className="text-[12px]">
                      <div className="flex flex-wrap items-center">
                        {validLength ? (
                          <div className="flex items-center mr-1">
                            <Tick className="w-5 mr-1" />
                            <p className="text-success-500">
                              Minimum 8 characters
                            </p>
                          </div>
                        ) : (
                          <div className="flex items-center mr-1">
                            <ErrorTick className="w-5 mr-1" />
                            <p className="text-[red]">Minimum 8 characters</p>
                          </div>
                        )}
                        {specialChar ? (
                          <div className="flex items-center mr-1">
                            <Tick className="w-5 mr-1" />
                            <p className="text-success-500">
                              Special character
                            </p>
                          </div>
                        ) : (
                          <div className="flex items-center mr-1">
                            <ErrorTick className="w-5 mr-1" />
                            <p className="text-[red]">Special character</p>
                          </div>
                        )}
                        {upperCase ? (
                          <div className="flex items-center mr-1">
                            <Tick className="w-5 mr-1" />
                            <p className="text-success-500">Uppercase letter</p>
                          </div>
                        ) : (
                          <div className="flex items-center mr-1">
                            <ErrorTick className="w-5 mr-1" />
                            <p className="text-[red]">Uppercase letter</p>
                          </div>
                        )}
                        {hasNumber ? (
                          <div className="flex items-center mr-1">
                            <Tick className="w-5 mr-1" />
                            <p className="text-success-500">Has number</p>
                          </div>
                        ) : (
                          <div className="flex items-center mr-1">
                            <ErrorTick className="w-5 mr-1" />
                            <p className="text-[red]">Has number</p>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div className="my-4">
                  <InputGroup className="items-center">
                    <InputLeftElement
                      top="auto"
                      pointerEvents="none"
                      children={<UilPadlock color="#B1B4BF" />}
                    />
                    <Input
                      value={values.confirmPassword}
                      onChange={handleChange("confirmPassword")}
                      type="password"
                      placeholder="Confirm Password"
                      color="#667085"
                      isRequired
                      _placeholder={{ opacity: 1, color: "#667085" }}
                    />
                  </InputGroup>
                </div>
                <div className="mt-8 mdx:mt-12">
                  <Button
                    type="submit"
                    isDisabled={!filled}
                    isLoading={isLoading}
                    className="bg-primary-600"
                    textColor="white"
                    bgColor="#340072"
                    w="full"
                    padding="6"
                    _hover={{ bgColor: "#240051" }}
                  >
                    {" "}
                    Reset Password
                  </Button>
                </div>
              </form>
            </div>
          </div>{" "}
        </>
      )}
    </>
  );
};
