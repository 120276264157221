import { useAppDispatch, useAppSelector } from "./useReduxState";
import { getSingleFeature } from "app/store/action-creators/feature-management.action";
import { useEffect, useState } from "react";
import { IFeature } from "data/models/feature-management";
import featureManagementService from "data/services/feature-management.service";

export const useFeatureMangement = () => {
  const dispatch = useAppDispatch();
  const [singleFeature] = useAppSelector((state) => [
    state.featureManagement.singleFeature
  ]);

  const dispatchSingleFeature = (feature: string) => {
    dispatch(getSingleFeature(feature));
  };

  return {
    singleFeature,
    dispatchSingleFeature
  };
};

export const useGetFeatures = (...features: string[]) => {
  const dispatch = useAppDispatch();

  const getFeatures = async (cb: any) => {
    const promises = features.map((f) => dispatch(getSingleFeature(f)));
    const res = await Promise.all(promises);
    cb(res);
  };

  return { getFeatures };
};

export const useFeatureMangementV2 = (feature: string) => {
  const [singleFeature, setSingleFeature] = useState<IFeature | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const res = await featureManagementService.getSingleFeature(feature);
        setSingleFeature(res.data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [feature]);

  return {
    singleFeature,
    loading
  };
};
