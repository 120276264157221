import { Spinner } from "@chakra-ui/react";
import { useState } from "react";

const IframeComponent = ({
  iframeRef,
  messageOrigin,
  refCode
}: {
  iframeRef: React.RefObject<HTMLIFrameElement>;
  messageOrigin: string;
  refCode: string;
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const handleIframeLoad = () => {
    setIsLoading(false);
    setHasError(false); // Reset error in case of retries or new loads
  };

  const handleIframeError = () => {
    setIsLoading(false);
    setHasError(true);
  };

  return (
    <div>
      {isLoading && (
        <div className="flex justify-center items-center py-12 h-screen">
          <Spinner className="text-primary-600" size="lg" />
        </div>
      )}
      {hasError && <p>Failed to load iframe</p>}

      <iframe
        ref={iframeRef}
        style={{
          border: "none",
          background: "transparent",
          overflow: "hidden",
          width: "100%",
          height: "calc(100vh - 1em)"
        }}
        title="MPWCheckout"
        src={`${messageOrigin}/popup/${refCode}`}
        //@ts-ignore
        frameborder="0"
        onLoad={handleIframeLoad}
        onError={handleIframeError}
      ></iframe>
    </div>
  );
};

export default IframeComponent;
