import { Button } from "@chakra-ui/react";
import { ReactComponent as PasswordSuccess } from "app/assets/svg/passwordSuccess.svg";
import { Link } from "react-router-dom";
export const ResetPasswordSuccessContainer = () => {
  return (
    <div className="w-11/12 p-6 bg-gray-100 mdx:w-5/12 sm:w-9/12">
      <div className="flex items-center justify-center my-2">
        <PasswordSuccess />
      </div>
      <p className="mt-5 text-center text-gray-900 text-heading100 mdx:text-heading100">
        Password Reset Successful!
      </p>
      <p className="mt-1 text-center text-gray-700">
        You have successfully changed your password. :)
      </p>

      <div className="mt-8 mdx:mt-12">
        <Link to={"/auth/login"}>
          <Button
            className="bg-primary-600"
            textColor="white"
            bgColor="#340072"
            w="full"
            padding="6"
            _hover={{ bgColor: "#240051" }}
          >
            {" "}
            Proceed to Login
          </Button>
        </Link>
      </div>
    </div>
  );
};
