import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { removeCallSign, removeToken } from "data/utils";

const TeamMemberVerification = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const isExistingUser = searchParams.get("isExistingUser");
  const email = searchParams.get("email");

  removeToken();
  removeCallSign();

  useEffect(() => {
    if (token && email) {
      if (isExistingUser === "true") {
        navigate(`/auth/login?teaminvite=true&email=${email}&token=${token}`);
      } else {
        navigate(
          `/auth/register?teaminvite=true&email=${email}&token=${token}`
        );
      }
    }
  }, [token, email, isExistingUser]);
  return (
    <div className="flex flex-row items-center justify-center h-[60vh]">
      <svg
        width="242"
        height="256"
        viewBox="0 0 242 256"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="w-24 h-24 animate-ping-slow"
      >
        <path
          d="M70.3489 138.642C49.191 138.624 29.4564 138.397 8.29843 138.694C-0.259052 138.816 -1.56887 135.821 1.5223 128.347C17.9474 88.6591 34.2328 48.9192 50.2738 9.06575C52.9545 2.41187 56.7967 -0.0767905 63.9483 0.00179865C92.4849 0.316155 121.021 0.00179866 149.558 0.150245C202.187 0.412209 236.46 39.9164 241.35 91.008C246.432 144.126 221.65 193.261 168.996 203.984C156.264 206.578 143.402 206.49 130.67 205.705C120.463 205.067 115.747 209.031 112.525 218.287C105.749 233.735 103.645 239.69 101.034 246.344C98.5799 252.875 95.122 256.019 87.6909 255.827C69.4233 255.364 51.1382 255.757 32.8619 255.644C22.7676 255.582 22.1039 254.648 26.0858 245.095C41.6901 207.599 50.9985 187.743 66.6902 150.29C69.6853 143.148 77.0727 138.834 84.7482 139.864C111.853 143.523 178.226 136.363 219.572 67.1606C136.006 102.412 94.1352 47.3561 94.1352 47.3561C94.1352 47.3561 112.691 90.4579 85.1586 128.748C81.0982 132.852 76.0335 136.075 70.3577 138.642H70.3489Z"
          fill="#390180"
        />
      </svg>
    </div>
  );
};

export default TeamMemberVerification;
