import {
  Modal,
  Input,
  Button,
  ModalBody,
  InputGroup,
  ModalHeader,
  ModalFooter,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  InputLeftElement
} from "@chakra-ui/react";
import { FC, useState } from "react";
import { useAuth } from "app/hooks/use-auth";
import { useNavigate } from "react-router-dom";
import { BaseModalProps } from "data/models/base";
import { decodeAssertion } from "data/utils/webauthn";
import { UilEnvelope } from "@iconscout/react-unicons";
import { MaybeWebAuthnCredential } from "data/models/auth";

const PasswordlessLogin: FC<BaseModalProps> = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const {
    isLoadingWebAuthn,
    dispatchWebAuthnFailed,
    dispatchWebAuthnAssertionEnd,
    dispatchWebAuthnAssertionBegin
  } = useAuth();

  const isSubmitDisabled = !email || isLoadingWebAuthn;

  const handleSubmit = async () => {
    if (isSubmitDisabled) return;
    const rawAssertion = await dispatchWebAuthnAssertionBegin(email);
    if (!rawAssertion) return;

    const assertion = decodeAssertion(rawAssertion);

    const credential: MaybeWebAuthnCredential = await navigator.credentials
      .get({ publicKey: assertion })
      .catch(() => false);

    if (!credential) return dispatchWebAuthnFailed();
    await dispatchWebAuthnAssertionEnd(
      email,
      navigate,
      credential as PublicKeyCredential
    );
  };

  const handleClose = () => {
    setEmail("");
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Passwordless Login</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <p className="mt-1 mb-1 text-gray-700">Enter your email address</p>

          <div className="my-4">
            <InputGroup className="items-center">
              <InputLeftElement
                top="auto"
                pointerEvents="none"
                children={<UilEnvelope color="#B1B4BF" />}
              />
              <Input
                size="lg"
                isRequired
                type="email"
                name="email"
                value={email}
                color="#444B59"
                placeholder="Email"
                focusBorderColor="none"
                onChange={(e) => setEmail(e.target.value)}
                _placeholder={{ opacity: 1, color: "#667085" }}
              />
            </InputGroup>
          </div>
        </ModalBody>

        <ModalFooter>
          <Button
            mb={4}
            w="full"
            colorScheme="primary"
            onClick={handleSubmit}
            isLoading={isLoadingWebAuthn}
            isDisabled={isSubmitDisabled}
            _hover={{ bgColor: "#240051" }}
            className="mdx:p-6 bg-primary-600"
          >
            Continue
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PasswordlessLogin;
