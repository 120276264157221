import {
  getAllCryptoWallets,
  getAllFiatWallets,
  getAllSubBusinessCryptoWallets,
  getAllSubBusinessFiatWallets,
  getsupportedCryptoWallets,
  getsupportedFiatWallets,
  setSelectedCryptoWallet
} from "app/store";
import { GetAllCryptoWalletResponse } from "app/store/models/wallet";
import { avalaibleCryptoNetworks } from "data/utils";
import { useCallback, useEffect, useState } from "react";
import ViewCryptoWalletNetworkOptions from "views/containers/wallet/fund-wallet/view-crypto-wallet-options";
import ViewSubBusinessCryptoWalletNetworkOptions from "views/containers/sub-businesses/wallet/fund-wallet/view-crypto-wallet-options";
import { useAppDispatch, useAppSelector } from "./useReduxState";
import FundWithCard from "views/containers/wallet/fund-wallet/fund-with-card";

/**
 * Input Component to handle flutterwave payment
 * @param closeOverlayingModalCallBack - call back function to close all modals: This is  because underlying modals clash with fultterwave's modal
 * @returns -  `FundWalletWithCard` component that handles the amount, currency and country code ton be passed into `useFlutterwavePayment` hook
 */
export const useFundWalletWithCard = (
  closeOverlayingModalCallBack: () => void
) => {
  /**
   *
   * @param props -
   * - `currency` - supported flutterwave currency code in which payment is to be processed in uppercase `(NGN`, `GHS...)`
   * - `country` - supported flutterwave country code in which payment is to be processed in uppercase `(NG`, `GH...)`
   * @returns-  `FundWithCard` component that handles the amount, currency and country code ton be passed into `useFlutterwavePayment` hook
   */
  const FundWalletWithCard = (props: { currency: string; country: string }) => {
    return (
      <FundWithCard
        {...props}
        closeOverlayingModalCallBack={closeOverlayingModalCallBack}
      />
    );
  };

  return { FundWalletWithCard };
};

/**
 * Custom Hook
 * @returns -
 * - `GetWalletNetworkModal` - Modal component that asks you to select a crypto network based off a coin
 * - `onOpenWalletHandler` - Fucntion to trigger open the modal
 */
export const useFundCryptoWalletOptionModal = () => {
  const dispatch = useAppDispatch();

  const [state, setState] = useState<string>("");

  const onClose = () => {
    setState("");
  };

  const GetWalletNetworkModal = useCallback(() => {
    return (
      <ViewCryptoWalletNetworkOptions
        isOpen={!!state}
        onClose={onClose}
        currency={state}
      />
    );
  }, [state]);

  /**
   * Fucntion to trigger open the `GetWalletNetworkModal` component
   * @param currency -  crypto coin code
   */
  const onOpenWalletHandler = (currency: string) => {
    if (currency === "usdt" || currency === "usdc") {
      return setState(currency);
    }
    return dispatch(
      setSelectedCryptoWallet({
        currency,
        network: "erc-20"
      })
    );
  };

  return { GetWalletNetworkModal, onOpenWalletHandler };
};

/**
 * Custom Hook
 * @remarks - Make sure the actions to get the fiat and crypto wallet states  are dispatched before calling the function
 * @returns -
 * - `isCreateWalletDisabled` -function that returns true if all crypto and fiat wallets have been created
 * - `isCreateFiatWalletDisabled` -function that returns true if all fiat wallets have been created
 * - `isCreateCryptoWalletDisabled` -function that returns true if all crypto wallets have been created
 */
export const useIsCreateWalletDisabled = () => {
  const [fiatWallets, supportedFiatWallets, cryptoWallets] = useAppSelector(
    (state) => [
      state.wallets.fiatWallets,
      state.misc.supportedFiatWallets.data,
      state.wallets.cryptoWallets
    ]
  );

  const isCreateWalletDisabled = useCallback(() => {
    return (
      fiatWallets.data.length === supportedFiatWallets.length &&
      cryptoWallets.data.length === 6
    );
  }, [
    fiatWallets.data.length,
    supportedFiatWallets.length,
    cryptoWallets.data.length
  ]);

  const isCreateFiatWalletDisabled = useCallback(() => {
    return fiatWallets.data.length === supportedFiatWallets.length;
  }, [fiatWallets.data.length, supportedFiatWallets.length]);

  const isCreateCryptoWalletDisabled = useCallback(() => {
    return cryptoWallets.data.length === 6;
  }, [cryptoWallets.data.length]);

  return {
    isCreateWalletDisabled,
    isCreateFiatWalletDisabled,
    isCreateCryptoWalletDisabled
  };
  // Note: Make sure the actions for the states here are dispatched before calling the function
};

/**
 * Custom hook to get created fiat wallets
 * @returns -
 * - `supportedFiatWallets` - list of supported fiat wallet currencies.
 * - `walletObject` - created wallets object with currency name as the key for easy lookup
 * - `loading` - loading state when getting supported fiat wallet from the store
 */
export const useAvalaibleFiatWallet = () => {
  const dispatch = useAppDispatch();
  const [supportedFiatWallets, loading, createdWallets] = useAppSelector(
    (state) => [
      state.misc.supportedFiatWallets.data,
      state.misc.supportedFiatWallets.loading,
      state.wallets.fiatWallets.data
    ]
  );

  const walletObject: { [key: string]: string } = {};

  createdWallets.forEach((el) => {
    walletObject[el.currency] = el.currency;
  });

  useEffect(() => {
    dispatch(getsupportedFiatWallets());
    // it is expected that the getAllFiatWallet action would have been dispatched in a higher order component before calling this hook, so there is no need to dispatch it here again
  }, []);

  return { supportedFiatWallets, walletObject, loading };
};

/**
 * Custom hook to get created crypto wallets
 * @returns -
 * - `supportedCryptoWallets` - list of supported crypto wallet currencies.
 * - `walletObject` - created wallets object with currency name as the key for easy lookup
 * - `loading` - loading state when getting supported crypto wallet from the state
 * - `getCreatedNetworks` - function that returns avalaible networks related to a coin with a disabled status whose boolean value depends on if the coin has been created on that network
 */
export const useAvalaibleCryptoWallet = () => {
  const dispatch = useAppDispatch();
  const [supportedCryptoWallets, loading, createdWallets] = useAppSelector(
    (state) => [
      state.misc.supportedCryptoWallets.data,
      state.misc.supportedCryptoWallets.loading,
      state.wallets.cryptoWallets.data
    ]
  );

  const walletObject: { [key: string]: string } = {};

  createdWallets.forEach((el) => {
    if (el.coin?.includes("usdt")) return;
    if (el.coin?.includes("usdc")) return;
    walletObject[el.coin] = el.coin;
  });

  /**
   * @param coin - supported changera crypto coin
   * @returns - all avalaible networks related to a coin with a disabled status whose boolean value depends on if the coin has been created on that network
   */
  const getCreatedNetworks = (coin: string) => {
    const networkObject: { [key: string]: string } = {};

    createdWallets.forEach((el) => {
      if (el.coin?.includes(coin)) {
        networkObject[el.network] = el.network;
      }
    });

    return avalaibleCryptoNetworks(coin).map((el) => ({
      ...el,
      disabled: Object.hasOwn(networkObject, el.network)
    }));
  };

  useEffect(() => {
    dispatch(getsupportedCryptoWallets());
    // it is expected that the getAllCryptoWallet action would have been dispatched in a higher order component before calling this hook, so there is no need to dispatch it here again
  }, []);

  return { supportedCryptoWallets, walletObject, loading, getCreatedNetworks };
};

/**
 *
 * @returns -
 * - `data` - a list of the first instance of a coin that has been created on one or more networks
 * - `isLoading` - loading state while crypto wallets are being fetched in the state
 * - `error`
 */
export const useCreatedCryptoWallets = () => {
  const [cryptoWallets, isLoading, error] = useAppSelector((state) => [
    state.wallets.cryptoWallets.data,
    state.wallets.cryptoWallets.loading,
    state.wallets.cryptoWallets.error
  ]);

  const counters = cryptoWallets.reduce((res, { coin }) => {
    res.set(coin, res.has(coin) ? res.get(coin) + 1 : 1);
    return res;
  }, new Map());

  const data = cryptoWallets.reduce((res: GetAllCryptoWalletResponse[], el) => {
    if (counters.has(el.coin)) {
      counters.delete(el.coin);
      res.push(el);
    }
    return res;
  }, []);

  const coinsFromERCNetwork = cryptoWallets.reduce(
    (res: GetAllCryptoWalletResponse[], el) => {
      if (el.network === "erc-20") {
        res.push(el);
      }
      return res;
    },
    []
  );

  return { data, isLoading, error, coinsFromERCNetwork };
};

/**
 *
 * @returns `dispatchFiatWallets` - funciton that gets fiat wallet list from the store
 */
export const useDispatchFiatWallets = () => {
  const dispatch = useAppDispatch();

  /**
   * Gets Fiat wallet from the store
   */
  const dispatchFiatWallets = () => {
    dispatch(getAllFiatWallets());
  };

  return { dispatchFiatWallets };
};

/**
 *
 * @returns `dispatchCryptoWallets` - funciton that gets crypto wallet list from the store
 */
export const useDispatchCryptoWallets = () => {
  const dispatch = useAppDispatch();

  /**
   * Gets Crypto wallet from the store
   */
  const dispatchCryptoWallets = () => {
    dispatch(getAllCryptoWallets());
  };

  return { dispatchCryptoWallets };
};

export const useCrypto = () => {
  const isCrypto = (coin: string): boolean => {
    return coin === "usdt" || coin === "cusd" || coin === "usdc";
  };

  return { isCrypto };
};

export const useWalletLimits = () => {
  const getWalletLimits = (plan: string) => {
    switch (plan) {
      case "silver":
        return {
          create: ["usd", "ngn", "ghs", "kes"]
        };
      case "gold":
        return { create: ["all"] };
      case "platinum":
        return { create: ["all"] };
      default:
        return { create: [] };
    }
  };

  const checkWalletLimits = (plan: string, wallet: string) => {
    const limits = getWalletLimits(plan);

    const create = limits.create.includes("all")
      ? true
      : limits.create.includes(wallet);

    return { create, fund: false };
  };

  const handleCreateWalletLimit = (plan: string, wallet: string) => {
    const { create } = checkWalletLimits(plan, wallet);
    return create;
  };

  return { handleCreateWalletLimit };
};

/**
 * Custom Hook
 * @remarks - Make sure the actions to get the fiat and crypto wallet states  are dispatched before calling the function
 * @returns -
 * - `isCreateWalletDisabled` -function that returns true if all crypto and fiat wallets have been created
 * - `isCreateFiatWalletDisabled` -function that returns true if all fiat wallets have been created
 * - `isCreateCryptoWalletDisabled` -function that returns true if all crypto wallets have been created
 */
export const useIsCreateSubBusinessWalletDisabled = () => {
  const [fiatWallets, supportedFiatWallets, cryptoWallets] = useAppSelector(
    (state) => [
      state.wallets.subBusiness.fiatWallets,
      state.misc.supportedFiatWallets.data,
      state.wallets.subBusiness.cryptoWallets
    ]
  );

  const isCreateWalletDisabled = useCallback(() => {
    return (
      fiatWallets.data.length === supportedFiatWallets.length &&
      cryptoWallets.data.length === 6
    );
  }, [
    fiatWallets.data.length,
    supportedFiatWallets.length,
    cryptoWallets.data.length
  ]);

  const isCreateFiatWalletDisabled = useCallback(() => {
    return fiatWallets.data.length === supportedFiatWallets.length;
  }, [fiatWallets.data.length, supportedFiatWallets.length]);

  const isCreateCryptoWalletDisabled = useCallback(() => {
    return cryptoWallets.data.length === 6;
  }, [cryptoWallets.data.length]);

  return {
    isCreateWalletDisabled,
    isCreateFiatWalletDisabled,
    isCreateCryptoWalletDisabled
  };
  // Note: Make sure the actions for the states here are dispatched before calling the function
};

/**
 * Custom hook to get created fiat wallets
 * @returns -
 * - `supportedFiatWallets` - list of supported fiat wallet currencies.
 * - `walletObject` - created wallets object with currency name as the key for easy lookup
 * - `loading` - loading state when getting supported fiat wallet from the store
 */
export const useAvalaibleSubBusinessFiatWallet = () => {
  const dispatch = useAppDispatch();
  const [supportedFiatWallets, loading, createdWallets] = useAppSelector(
    (state) => [
      state.misc.supportedFiatWallets.data,
      state.misc.supportedFiatWallets.loading,
      state.wallets.subBusiness.fiatWallets.data
    ]
  );

  const walletObject: { [key: string]: string } = {};

  createdWallets.forEach((el) => {
    walletObject[el.fiatWallet.currency] = el.fiatWallet.currency;
  });

  useEffect(() => {
    dispatch(getsupportedFiatWallets());
    // it is expected that the getAllFiatWallet action would have been dispatched in a higher order component before calling this hook, so there is no need to dispatch it here again
  }, []);

  return { supportedFiatWallets, walletObject, loading };
};

/**
 * Custom hook to get created crypto wallets
 * @returns -
 * - `supportedCryptoWallets` - list of supported crypto wallet currencies.
 * - `walletObject` - created wallets object with currency name as the key for easy lookup
 * - `loading` - loading state when getting supported crypto wallet from the state
 * - `getCreatedNetworks` - function that returns avalaible networks related to a coin with a disabled status whose boolean value depends on if the coin has been created on that network
 */
export const useAvalaibleSubBusinessCryptoWallet = () => {
  const dispatch = useAppDispatch();
  const [supportedCryptoWallets, loading, createdWallets] = useAppSelector(
    (state) => [
      state.misc.supportedCryptoWallets.data,
      state.misc.supportedCryptoWallets.loading,
      state.wallets.subBusiness.cryptoWallets.data
    ]
  );

  const walletObject: { [key: string]: string } = {};

  createdWallets.forEach((el) => {
    if (el.coin?.includes("usdt")) return;
    if (el.coin?.includes("usdc")) return;
    walletObject[el.coin] = el.coin;
  });

  /**
   * @param coin - supported changera crypto coin
   * @returns - all avalaible networks related to a coin with a disabled status whose boolean value depends on if the coin has been created on that network
   */
  const getCreatedNetworks = (coin: string) => {
    const networkObject: { [key: string]: string } = {};

    createdWallets.forEach((el) => {
      if (el.coin?.includes(coin)) {
        networkObject[el.network] = el.network;
      }
    });

    return avalaibleCryptoNetworks(coin).map((el) => ({
      ...el,
      disabled: Object.hasOwn(networkObject, el.network)
    }));
  };

  useEffect(() => {
    dispatch(getsupportedCryptoWallets());
    // it is expected that the getAllCryptoWallet action would have been dispatched in a higher order component before calling this hook, so there is no need to dispatch it here again
  }, []);

  return { supportedCryptoWallets, walletObject, loading, getCreatedNetworks };
};

/**
 *
 * @returns `dispatchFiatWallets` - funciton that gets fiat wallet list from the store
 */
export const useDispatchSubBusinessFiatWallets = () => {
  const dispatch = useAppDispatch();

  /**
   * Gets Fiat wallet from the store
   */
  const dispatchFiatWallets = (callSign: string) => {
    dispatch(getAllSubBusinessFiatWallets(callSign));
  };

  return { dispatchFiatWallets };
};

/**
 *
 * @returns `dispatchCryptoWallets` - funciton that gets crypto wallet list from the store
 */
export const useDispatchSubBusinessCryptoWallets = () => {
  const dispatch = useAppDispatch();

  /**
   * Gets Crypto wallet from the store
   */
  const dispatchCryptoWallets = (callSign: string) => {
    dispatch(getAllSubBusinessCryptoWallets(callSign));
  };

  return { dispatchCryptoWallets };
};

/**
 *
 * @returns -
 * - `data` - a list of the first instance of a coin that has been created on one or more networks
 * - `isLoading` - loading state while crypto wallets are being fetched in the state
 * - `error`
 */
export const useCreatedSubBusinessCryptoWallets = () => {
  const [cryptoWallets, isLoading, error] = useAppSelector((state) => [
    state.wallets.subBusiness.cryptoWallets.data,
    state.wallets.subBusiness.cryptoWallets.loading,
    state.wallets.subBusiness.cryptoWallets.error
  ]);

  const counters = cryptoWallets.reduce((res, { coin }) => {
    res.set(coin, res.has(coin) ? res.get(coin) + 1 : 1);
    return res;
  }, new Map());

  const data = cryptoWallets.reduce((res: GetAllCryptoWalletResponse[], el) => {
    if (counters.has(el.coin)) {
      counters.delete(el.coin);
      res.push(el);
    }
    return res;
  }, []);

  const coinsFromERCNetwork = cryptoWallets.reduce(
    (res: GetAllCryptoWalletResponse[], el) => {
      if (el.network === "erc-20") {
        res.push(el);
      }
      return res;
    },
    []
  );

  return { data, isLoading, error, coinsFromERCNetwork };
};

/**
 * Custom Hook
 * @returns -
 * - `GetWalletNetworkModal` - Modal component that asks you to select a crypto network based off a coin
 * - `onOpenWalletHandler` - Fucntion to trigger open the modal
 */
export const useFundSubBusinessCryptoWalletOptionModal = () => {
  const dispatch = useAppDispatch();

  const [state, setState] = useState<string>("");

  const onClose = () => {
    setState("");
  };

  const GetWalletNetworkModal = useCallback(() => {
    return (
      <ViewSubBusinessCryptoWalletNetworkOptions
        isOpen={!!state}
        onClose={onClose}
        currency={state}
      />
    );
  }, [state]);

  /**
   * Fucntion to trigger open the `GetWalletNetworkModal` component
   * @param currency -  crypto coin code
   */
  const onOpenWalletHandler = (currency: string) => {
    if (currency === "usdt" || currency === "usdc") {
      return setState(currency);
    }
    return dispatch(
      setSelectedCryptoWallet({
        currency,
        network: "erc-20"
      })
    );
  };

  return { GetWalletNetworkModal, onOpenWalletHandler };
};
