import { Image } from "@chakra-ui/react";
import { Link, Outlet } from "react-router-dom";
import { BackDecoration } from "./back-decoration";

const Logo =
  "https://res.cloudinary.com/bitmama/image/upload/v1726670324/payborda/SVG/White/Horizontal_White_yshaaz.svg";

const AuthLayout = (): JSX.Element => {
  return (
    <div>
      <div className="h-screen bg-primary-600">
        <div className="hidden lg:block relative">
          <BackDecoration />
        </div>
        <div className="w-full px-2 py-6 overflow-y-auto h-full mdx:py-16 mdx:px-16 bg-primary-600">
          <div className="relative">
            <div className="flex justify-center">
              <Link to={"/"}>
                <Image src={Logo} className="w-36" />
              </Link>
            </div>
            <div className="flex items-center justify-center w-full mt-8 overflow-y-visible mdx:mt-12">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
