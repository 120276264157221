import ReactDOM from "react-dom";
import {
  Box,
  Flex,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from "@chakra-ui/react";
import { useAppSelector } from "app/hooks";

// this is the default one @tofmat created
export const LoadingSpin = (): JSX.Element => {
  return (
    <div className="flex justify-center items-center h-screen">
      <svg
        width="242"
        height="256"
        viewBox="0 0 242 256"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="w-24 h-24 animate-spin"
      >
        <path
          d="M70.3489 138.642C49.191 138.624 29.4564 138.397 8.29843 138.694C-0.259052 138.816 -1.56887 135.821 1.5223 128.347C17.9474 88.6591 34.2328 48.9192 50.2738 9.06575C52.9545 2.41187 56.7967 -0.0767905 63.9483 0.00179865C92.4849 0.316155 121.021 0.00179866 149.558 0.150245C202.187 0.412209 236.46 39.9164 241.35 91.008C246.432 144.126 221.65 193.261 168.996 203.984C156.264 206.578 143.402 206.49 130.67 205.705C120.463 205.067 115.747 209.031 112.525 218.287C105.749 233.735 103.645 239.69 101.034 246.344C98.5799 252.875 95.122 256.019 87.6909 255.827C69.4233 255.364 51.1382 255.757 32.8619 255.644C22.7676 255.582 22.1039 254.648 26.0858 245.095C41.6901 207.599 50.9985 187.743 66.6902 150.29C69.6853 143.148 77.0727 138.834 84.7482 139.864C111.853 143.523 178.226 136.363 219.572 67.1606C136.006 102.412 94.1352 47.3561 94.1352 47.3561C94.1352 47.3561 112.691 90.4579 85.1586 128.748C81.0982 132.852 76.0335 136.075 70.3577 138.642H70.3489Z"
          fill="#390180"
        />
      </svg>
    </div>
  );
};

// other ones @orololuwa added
export const LoadingPing = (): JSX.Element => {
  return (
    <div className="flex justify-center items-center h-screen bg-[#6a2cc031]">
      <svg
        width="242"
        height="256"
        viewBox="0 0 242 256"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="w-24 h-24 animate-ping-slow"
      >
        <path
          d="M70.3489 138.642C49.191 138.624 29.4564 138.397 8.29843 138.694C-0.259052 138.816 -1.56887 135.821 1.5223 128.347C17.9474 88.6591 34.2328 48.9192 50.2738 9.06575C52.9545 2.41187 56.7967 -0.0767905 63.9483 0.00179865C92.4849 0.316155 121.021 0.00179866 149.558 0.150245C202.187 0.412209 236.46 39.9164 241.35 91.008C246.432 144.126 221.65 193.261 168.996 203.984C156.264 206.578 143.402 206.49 130.67 205.705C120.463 205.067 115.747 209.031 112.525 218.287C105.749 233.735 103.645 239.69 101.034 246.344C98.5799 252.875 95.122 256.019 87.6909 255.827C69.4233 255.364 51.1382 255.757 32.8619 255.644C22.7676 255.582 22.1039 254.648 26.0858 245.095C41.6901 207.599 50.9985 187.743 66.6902 150.29C69.6853 143.148 77.0727 138.834 84.7482 139.864C111.853 143.523 178.226 136.363 219.572 67.1606C136.006 102.412 94.1352 47.3561 94.1352 47.3561C94.1352 47.3561 112.691 90.4579 85.1586 128.748C81.0982 132.852 76.0335 136.075 70.3577 138.642H70.3489Z"
          fill="#390180"
        />
      </svg>
    </div>
  );
};

export const LoadingPulse = (): JSX.Element => {
  return (
    <div className="flex justify-center items-center h-screen bg-[#6a2cc031]">
      <svg
        width="242"
        height="256"
        viewBox="0 0 242 256"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="w-24 h-24 animate-ping"
      >
        <path
          d="M70.3489 138.642C49.191 138.624 29.4564 138.397 8.29843 138.694C-0.259052 138.816 -1.56887 135.821 1.5223 128.347C17.9474 88.6591 34.2328 48.9192 50.2738 9.06575C52.9545 2.41187 56.7967 -0.0767905 63.9483 0.00179865C92.4849 0.316155 121.021 0.00179866 149.558 0.150245C202.187 0.412209 236.46 39.9164 241.35 91.008C246.432 144.126 221.65 193.261 168.996 203.984C156.264 206.578 143.402 206.49 130.67 205.705C120.463 205.067 115.747 209.031 112.525 218.287C105.749 233.735 103.645 239.69 101.034 246.344C98.5799 252.875 95.122 256.019 87.6909 255.827C69.4233 255.364 51.1382 255.757 32.8619 255.644C22.7676 255.582 22.1039 254.648 26.0858 245.095C41.6901 207.599 50.9985 187.743 66.6902 150.29C69.6853 143.148 77.0727 138.834 84.7482 139.864C111.853 143.523 178.226 136.363 219.572 67.1606C136.006 102.412 94.1352 47.3561 94.1352 47.3561C94.1352 47.3561 112.691 90.4579 85.1586 128.748C81.0982 132.852 76.0335 136.075 70.3577 138.642H70.3489Z"
          fill="#390180"
        />
      </svg>
    </div>
  );
};

export const LoadingBounce = (): JSX.Element => {
  return (
    <div className="flex justify-center items-center h-screen bg-[#6a2cc031]">
      <svg
        width="242"
        height="256"
        viewBox="0 0 242 256"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="w-24 h-24 animate-bounce"
      >
        <path
          d="M70.3489 138.642C49.191 138.624 29.4564 138.397 8.29843 138.694C-0.259052 138.816 -1.56887 135.821 1.5223 128.347C17.9474 88.6591 34.2328 48.9192 50.2738 9.06575C52.9545 2.41187 56.7967 -0.0767905 63.9483 0.00179865C92.4849 0.316155 121.021 0.00179866 149.558 0.150245C202.187 0.412209 236.46 39.9164 241.35 91.008C246.432 144.126 221.65 193.261 168.996 203.984C156.264 206.578 143.402 206.49 130.67 205.705C120.463 205.067 115.747 209.031 112.525 218.287C105.749 233.735 103.645 239.69 101.034 246.344C98.5799 252.875 95.122 256.019 87.6909 255.827C69.4233 255.364 51.1382 255.757 32.8619 255.644C22.7676 255.582 22.1039 254.648 26.0858 245.095C41.6901 207.599 50.9985 187.743 66.6902 150.29C69.6853 143.148 77.0727 138.834 84.7482 139.864C111.853 143.523 178.226 136.363 219.572 67.1606C136.006 102.412 94.1352 47.3561 94.1352 47.3561C94.1352 47.3561 112.691 90.4579 85.1586 128.748C81.0982 132.852 76.0335 136.075 70.3577 138.642H70.3489Z"
          fill="#390180"
        />
      </svg>
    </div>
  );
};
//

export const LoadingText = () => (
  <Box mt={16} display="flex" alignItems="center" justifyContent="center">
    Loading...
  </Box>
);

export const LoadingNoBg = () => {
  const loading = useAppSelector((state) => state.app.loading);
  return loading
    ? ReactDOM.createPortal(
        <Flex
          className="loadingIcon"
          alignItems="center"
          justifyContent="center"
          position="fixed"
          top="0"
          left="0"
          height="100vh"
          width="100vw"
          zIndex="sticky"
          background="#0D132988"
        >
          <svg
            width="729"
            height="690"
            viewBox="0 0 729 690"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M211.919 373.704C148.183 373.657 88.7343 373.045 24.9982 373.845C-0.780367 374.175 -4.72606 366.101 4.58578 345.954C54.0648 238.977 103.123 131.86 151.445 24.4364C159.52 6.5011 171.094 -0.206986 192.638 0.0048482C278.601 0.852185 364.565 0.00484822 450.528 0.404979C609.066 1.11109 712.312 107.593 727.042 245.309C742.352 388.485 667.699 520.929 509.082 549.832C470.73 556.823 431.983 556.587 393.631 554.469C362.881 552.751 348.677 563.437 338.97 588.386C318.558 630.023 312.218 646.076 304.353 664.011C296.962 681.617 286.545 690.09 264.16 689.572C209.131 688.325 154.049 689.384 98.9931 689.078C68.5849 688.913 66.5858 686.395 78.5807 660.645C125.587 559.577 153.628 506.053 200.897 405.102C209.92 385.849 232.173 374.222 255.295 376.999C336.945 386.861 536.886 367.561 661.439 181.029C409.703 276.048 283.573 127.647 283.573 127.647C283.573 127.647 339.47 243.826 256.532 347.036C244.3 358.099 229.043 366.784 211.945 373.704H211.919Z"
              fill="#390180"
            />
          </svg>
        </Flex>,
        document.getElementById("changera-loading") as HTMLElement
      )
    : null;
};

export const LoadingTable = ({
  pageSize,
  headers
}: {
  pageSize: number;
  headers: string[];
}) => {
  return (
    <TableContainer>
      <Table variant="simple">
        <Thead textColor={"gray.900"}>
          <Tr>
            {headers.map((el, idx) => (
              <Th
                key={idx}
                textTransform="capitalize"
                color="gray.900"
                fontSize={"md"}
              >
                {el}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {Array.from(Array(pageSize).keys()).map((el) => (
            <Tr key={el}>
              {headers.map((el) => (
                <Td key={el}>
                  <Skeleton width="100%" height="30px" />
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default LoadingPing;
