import { Box, Heading, Image, Text } from "@chakra-ui/react";
import { BackDecoration } from "views/layout/auth-layout/back-decoration";

const Logo =
  "https://res.cloudinary.com/bitmama/image/upload/v1726670324/payborda/SVG/White/Horizontal_White_yshaaz.svg";

export default function NoConnection({}: IErrorView) {
  return (
    <div>
      <div className="h-screen bg-primary-600 relative">
        <Box
          className="flex items-center justify-center w-full mt-8 overflow-y-visible mdx:mt-12"
          position={"absolute"}
          top="50%"
          left="50%"
          transform={"translate(-50%, -50%)"}
        >
          <Box textAlign="center" py={10} px={6} color="white">
            <Heading display="inline-block" as="h2" size="2xl">
              No Internet Connection
            </Heading>
            <Text fontSize="18px" mt={3} mb={2}>
              Please check your connection
            </Text>
          </Box>
        </Box>
        <div className="relative">
          <BackDecoration />
        </div>
        <div className="w-full px-2 py-6 overflow-y-auto mdx:py-16 mdx:px-16 bg-primary-600">
          <div className="relative">
            <div className="flex justify-center mdx:justify-start">
              <Image src={Logo} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

interface IErrorView {
  text: string;
}
